export default function(text) {
  if (!text) {
    return;
  }
  const task = {
    name: '',
    steps: [],
  };
  const text_lines = text?.split('\n');

  task.name = text_lines[0].split('=')[1];
  let substeps = [];
  let step = '';
  for (let line of text_lines) {
    line = line.trim();

    if (line[line.length - 1] == ':' && substeps.length == 0) {
      step = line.slice(0, -1);
    } else if (line[line.length - 1] == ';') {
      substeps.push(line.slice(0, -1));
    } else if (line[line.length - 1] == ':' && substeps.length != 0) {
      task.steps.push({
        name: step,
        substeps,
      });
      substeps = [];
      step = line.slice(0, -1);
    }
  }
  task.steps.push({
    name: step,
    substeps,
  });
  return task;
}
