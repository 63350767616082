<template>
  <a-card
    class="h-100"
    :body-style="{ height: '100% !important', padding: '1rem 1rem 4rem' }"
  >
    <a-modal
      id="clear-labels-modal"
      v-model:visible="isClearLabels"
      centered
      title="Clear Labels"
      ok-text="Yes"
      :ok-button-props="{ id: 'clear-labels-ok-btn' }"
      :cancel-button-props="{
        id: 'clear-labels-cancel-btn',
        style: { marginLeft: 'auto' }
      }"
      @ok="handleClearLabels"
    >
      <template #closeIcon>
        <close-outlined id="clear-labels-close-btn" />
      </template>
      <p>Are you sure you want to clear All labels?</p>
    </a-modal>
    <a-row class="h-100">
      <a-col id="annotate-video-steps-box" span="8" class="h-100">
        <div :style="getStyle">
          <div
            class="d-flex align-items-center mx-auto rounded"
            :class="{
              'list-group-item list-group-item-success':
                isCycleCompleted && isLabeler,
              'list-group-item list-group-item-danger':
                !isCycleCompleted && isLabeler
            }"
            style="flex: 1 1 auto"
          >
            <small>Frame: {{ currentSliderVal }} / Time: {{ duration }}</small>

            <i
              v-if="isLabeler"
              class="bi ml-2 d-flex"
              :class="{
                'bi-check-circle-fill text-success': isCycleCompleted,
                'bi-x-circle-fill text-danger': !isCycleCompleted
              }"
            />
            <small v-if="isLabeler && !isCycleCompleted" class="ml-2"
              >Please make sure to complete the current cycle!</small
            >
          </div>
        </div>
        <div
          id="av-painter-container-left"
          ref="stepContainer"
          class="pr-0 painter"
          style="height: 105% !important"
          :style="`height: ${zoomIn ? 20 : 23}vh !important;`"
          data-name="painterContainer"
          :class="{ 'of-hidden': !zoomIn }"
          @scroll="handleScroll"
        >
          <div
            v-for="(step, index) in substepsList"
            :id="'av-labelling-step-' + step.name"
            :key="index"
            class="m-0 pl-1 border progress-container"
            :class="getStepElementClasses(step, index)"
            @click="handleSelect(step, index)"
          >
            {{ getStepName(step.name) }}
            <div class="d-inline-flex ml-auto mr-2">
              <i
                v-if="selectedStep === `${index}-${step.name}`"
                class="bi bi-asterisk mr-2"
              />

              <QrcodeOutlined v-if="isBarcodedStep(step.name)" />
            </div>
          </div>
        </div>
      </a-col>

      <a-col
        id="av-zoom-slider-container"
        ref="zoomSliderContainer"
        span="16"
        class="ml-0 px-0"
        :class="{ 'slider-div': zoomIn }"
        :style="`padding-bottom:1rem;`"
      >
        <div :style="{ width: getWidth, height: zoomIn ? '22.4vh' : '23.1vh' }">
          <div class="pl-0" style="padding-right: 0.8em; padding-bottom: 0.8em">
            <!-- <Slider
              id="slider label-video-slider"
              v-model="currentSliderVal"
              :min="0"
              :max="totalFrames"
              :style="sliderStyle"
              :lazy="false"
            /> -->
            <a-slider
              v-model:value="currentSliderVal"
              tooltip-visible
              :min="0"
              :max="totalFrames"
              class="m-0"
              :track-style="sliderStyle"
              :handle-style="{ width: '1.3em', height: '1.3em' }"
            />
          </div>

          <div
            id="painter-right-container"
            ref="painterContainer"
            class="pl-0 painter"
            :style="{ width: getWidth, height: getHeight }"
            data-name="stepContainer"
            @scroll="handleScroll"
          >
            <div
              v-for="(step, stepIndex) in substepsList"
              :id="'step-' + step.name + '-painter'"
              :key="step.name"
              class="p-0 m-0 border progress-container d-flex"
            >
              <div ref="vline" class="vline" :style="vLineStyle" />
              <painter-component
                v-for="(interval, intervalIndex) in step.interval"
                :key="intervalIndex"
                :interval-index="intervalIndex"
                :interval="interval"
                :step="step"
                :step-index="stepIndex"
                :prev-end="getPrevEnd(step, intervalIndex)"
                :step-to-remove="stepToRemove"
                :step-to-move="stepToMove"
                :current-slider-percent="currentSliderPercent"
                :total-frames="totalFrames"
                :is-visualize="isVisualize"
                :label-moving="moveLabel"
                @handleSelect="handleSelect"
                @handleContextMenu="handleContextMenu"
                @handleEditLabel="handleEditLabel"
                @handleRemoveLabel="handleRemoveLabel"
                @handleMoveLabel="handleMoveLabel"
                @handleClickCurrentInterval="handleClickActiveInterval"
              />
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-card>
</template>
<script>
import PainterComponent from './Painter.vue';
import httpClient from '../../../../service/httpClient';
import { POSITION } from 'vue-toastification';
import SpinnerComponent from '../../../shared/Components/Spinner.vue';
import Slider from '@vueform/slider';
import '@vueform/slider/themes/default.css';
import { CloseOutlined, QrcodeOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import { roles } from 'src/config/roles-config';
import S3Service from 'src/services/s3';

export default {
  components: {
    PainterComponent,
    SpinnerComponent,
    CloseOutlined,
    Slider,
    QrcodeOutlined
  },
  inject: ['toast'],
  props: [
    'stepsList',
    'videoInfo',
    'totalFrames',
    'taskId',
    'taskName',
    'frame',
    'isVisualize',
    'stepsToVisualize',
    'zoomIn',
    'updateTaskRecord',
    'organization',
    'stepsTranslationMapping',
    'stepsToIndexMapping',
    'barcodedSteps'
  ],
  emits: ['onSave', 'frameChange'],
  data: function() {
    return {
      log: console.log,
      substepsList: [],
      prevSubstepsList: null,
      currentSliderVal: 0,
      currentSliderPercent: 0,
      selectedStep: null,
      stepToPaint: null,
      // totalFrames: 1000000,
      intervalIndex: -1,
      currentFrame: 0,
      duration: '00:00',
      loading: false,
      sliderValue: 0,
      showPainter: true,
      isCycleCompleted: null,
      showContextMenu: false,
      stepsToMark: null,
      prevStepsToPaint: null,
      stepToRemove: null,
      toastId: '',
      currentStepStart: 0,
      startEndSteps: ['123abc', '456zxc'],
      //============= Annotation Session
      startTime: null,
      endTime: null,
      connection: null,
      editLabel: false,
      moveLabel: false,
      stepToMove: null,
      editLabelPosition: null,
      isSliderMoveLeftSet: false,
      isSliderMoveRightSet: false,
      prevMousePosition: 0,
      currentStepId: null,
      currentStepToPaint: null,
      currentIntervalToPaint: null,
      isClearLabels: false,
      saveProgressInterval: null
    };
  },
  mounted() {
    !this.isVisualize ? this.setListeners() : this.setVisualizeListener();
    this.substepsList = this.getSubStepsList(this.stepsList);
    this.duration = this.videoInfo?.duration;
    if (this.isVisualize) this.showVisualization();
    else this.fillPainter();
  },

  beforeUnmount() {
    this.unsetListeners();
    clearInterval(this.saveProgressInterval);
    this.isVisualize && this.unsetVisualizeListener();
  },

  computed: {
    ...mapGetters([
      'prevMetaData',
      'role',
      'frameStepSize',
      'isSubstepsChanged'
    ]),
    getWidth() {
      if (this.totalFrames > 1800) {
        return this.zoomIn ? `${this.totalFrames}px !important` : '100%';
      }
      return '100%';
    },
    getHeight() {
      if (this.totalFrames > 1800) {
        return this.zoomIn ? `90%` : '100%';
      }
      return '100%';
    },
    sliderStyle() {
      return {
        width: this.zoomIn ? `${this.totalFrames - 10}px !important` : '100%',
        height: '10px'
      };
    },

    isLabeler() {
      return [true, false].includes(this.isCycleCompleted);
    },

    vLineStyle() {
      return {
        left: this.currentSliderPercent + '%'
      };
    },

    getStyle() {
      const temp = this.zoomIn ? '0.4em' : '0.4em';
      return {
        marginBottom: temp + ' !important'
      };
    }
  },
  watch: {
    // frame(value) {
    //   this.currentSliderVal = value;
    // },
    isSubstepsChanged(value) {
      if (!this.saveProgressInterval && value) {
        this.saveProgressInterval = setInterval(this.handleSaveProgress, 12000);
      }
    },

    currentSliderVal(value) {
      this.$emit('frameChange', value);
      this.editLabel
        ? this.editStepPaint()
        : !this.moveLabel && this.paintStep(value);
      this.currentSliderPercent = (value / this.totalFrames) * 100;
      this.handleHorizontalScroll();
    },

    stepsList(list) {
      this.substepsList = this.getSubStepsList(list);
    },

    totalFrames() {
      if (this.isVisualize) {
        this.showVisualization();
      } else {
        this.fillPainter();
      }
    },

    prevMetaData() {
      this.fillPainter();
    },

    stepsToVisualize(value) {
      if (!value) return;
      this.showVisualization();
    },

    substepsList(value) {
      this.setIsSubstepsChanged(
        JSON.stringify(value) !== JSON.stringify(this.prevSubstepsList)
      );
    }
  },

  methods: {
    ...mapActions(['setIsSavingAnnoation', 'setIsSubstepsChanged']),

    isBarcodedStep(stepName) {
      const stepIndex = this.stepsToIndexMapping[stepName];
      return this.barcodedSteps?.includes(stepIndex);
    },

    isOverlapped(stepIndex, stepType) {
      const { interval } = this.substepsList[stepIndex];
      const currentSlider = this.currentSliderPercent;
      return (
        stepType !== 'step' &&
        interval.some(
          ({ start, end }) => start <= currentSlider && currentSlider <= end
        )
      );
    },

    paintStep(value) {
      if (!this.stepToPaint) return;
      const {
        temp,
        index,
        currentInterval,
        neighbourInterval: nextInterval
      } = this.getIntervalsData('next');
      const end = (value / this.totalFrames) * 100;
      if (currentInterval?.start > end) return;
      if (
        (nextInterval && end >= nextInterval.start) ||
        !temp[index].interval[this.intervalIndex]
      )
        return;

      temp[index].interval[this.intervalIndex].end = end;
      const parentIndex = temp[index].parent;
      this.updateStepPainter(temp, parentIndex);
      this.prevStepsToPaint = null;
    },

    handleEditLabel(editSliderValue, editIntervalIndex, editStartPosition) {
      this.editLabel = true;
      this.moveLabel = false;
      this.intervalIndex = editIntervalIndex;
      this.currentSliderVal = editSliderValue;
      this.editLabelPosition = editStartPosition;
      // this.currentSliderPercent = (editSliderValue / this.totalFrames) * 100;
      this.removePrevSelectedStep();
      this.stepToPaint = this.selectedStep;
    },

    editStepPaint() {
      if (!this.stepToPaint) return;
      this.editLabelPosition === 'start'
        ? this.editLabelStart()
        : this.editLabelPosition === 'end' && this.editLabelEnd();
    },

    editLabelStart() {
      const {
        temp,
        index,
        currentInterval,
        neighbourInterval: prevInterval
      } = this.getIntervalsData('previous');
      const newStart = (this.currentSliderVal / this.totalFrames) * 100;
      // return if start is greater then end
      if (currentInterval && currentInterval.end < newStart) return;
      // return if start is merged with the previous label start
      if ((prevInterval && newStart <= prevInterval.end) || !currentInterval)
        return this.handlePromptWhenLabelMerged();
      temp[index].interval[this.intervalIndex].start = newStart;
      const parentIndex = temp[index].parent;
      this.updateStepPainter(temp, parentIndex);
      this.prevStepsToPaint = null;
    },

    editLabelEnd() {
      const {
        temp,
        index,
        currentInterval,
        neighbourInterval: nextInterval
      } = this.getIntervalsData('next');
      const newEnd = (this.currentSliderVal / this.totalFrames) * 100;
      // return if end is smaller then start
      if (currentInterval && currentInterval.start > newEnd) return;
      // return if end is merged with the next label start
      if ((nextInterval && newEnd >= nextInterval.start) || !currentInterval)
        return this.handlePromptWhenLabelMerged();
      temp[index].interval[this.intervalIndex].end = newEnd;

      const parentIndex = temp[index].parent;
      this.updateStepPainter(temp, parentIndex);
      this.prevStepsToPaint = null;
    },

    getStepName(name) {
      const maxChars = 55;
      const stepName = this.stepsTranslationMapping[name];
      let tempName = stepName?.slice(0, maxChars);
      if (stepName?.length > maxChars) tempName += '...';
      return tempName;
    },

    markStep(position) {
      if (this.stepsToMark.interval.length === 0) return;
      const temp = [...this.substepsList];

      temp[position].interval.push(this.stepsToMark.interval.at(-1));

      this.substepsList = temp;
      this.stepsToMark = null;

      this.updateStepPainter(this.substepsList, temp[position].parent);
      this.selectedStep = null;
      this.stepToPaint = null;
    },

    setVisualizeListener() {
      document.addEventListener('keydown', this.handleKeyDownEvents);
    },
    unsetVisualizeListener() {
      document.removeEventListener('keydown', this.handleKeyDownEvents);
    },

    setListeners() {
      console.log('set listn');
      document.addEventListener('keyup', this.handleKeyUpEvents);
      document.addEventListener('keydown', this.handleKeyDownEvents);
      document.addEventListener('click', this.handleOutsideClick);
      document.addEventListener('contextmenu', this.handleOutsideClick);
      document.addEventListener('mousedown', this.handleMouseDownEvents);
      document.addEventListener('mouseup', this.handleMouseUpEvents);
      this.emitter.on('clear-current', this.clearCurrentLabel);
      this.emitter.on('clear-all', () => (this.isClearLabels = true));
      this.emitter.on('save-label', this.handleSave);
      this.startTime = new Date().toISOString();
    },

    unsetListeners() {
      document.removeEventListener('keyup', this.handleKeyUpEvents);
      document.removeEventListener('keydown', this.handleKeyDownEvents);
      document.removeEventListener('click', this.handleOutsideClick);
      document.removeEventListener('contextmenu', this.handleOutsideClick);
      document.removeEventListener('mousedown', this.handleMouseDownEvents);
      document.removeEventListener('mouseup', this.handleMouseUpEvents);
      this.emitter.off('clear-current', this.clearCurrentLabel);
      this.emitter.off('clear-all', () => (this.isClearLabels = false));
      this.emitter.off('save-label', this.handleSave);
    },

    handleMoveLabel(moveStepIndex, moveIntervalIndex) {
      this.moveLabel = true;
      this.editLabel = false;
      this.stepToMove = {
        stepIndex: moveStepIndex,
        intervalIndex: moveIntervalIndex
      };
      this.intervalIndex = moveIntervalIndex;
      this.stepToPaint = this.selectedStep;
    },

    handleMouseDownEvents(e) {
      e.preventDefault();
      if (this.moveLabel && e.target.parentElement.id === this.currentStepId) {
        document.addEventListener('mousemove', this.handleMouseMoveEvents);
      }
    },

    handleMouseMoveEvents(e) {
      e.preventDefault();
      if (this.moveLabel) {
        const currentIntervalData = this.currentIntervalToPaint;
        if (e.clientX < this.prevMousePosition) {
          //left
          if (e.clientX > currentIntervalData.getBoundingClientRect().left) {
            if (!this.isSliderMoveLeftSet) {
              this.isSliderMoveRightSet = false;
              this.isSliderMoveLeftSet = true;
              const {
                left
              } = this.currentIntervalToPaint.getBoundingClientRect();
              this.handleMoveLabelToLeft({
                event: 'mouse',
                positionX: left
              });
            }
          } else
            this.handleMoveLabelToLeft({
              event: 'mouse',
              positionX: e.clientX
            });
        } else if (e.clientX > this.prevMousePosition) {
          //right
          if (e.clientX < currentIntervalData.getBoundingClientRect().right) {
            if (!this.isSliderMoveRightSet) {
              this.isSliderMoveLeftSet = false;
              this.isSliderMoveRightSet = true;
              const {
                right
              } = this.currentIntervalToPaint.getBoundingClientRect();
              this.handleMoveLabelToRight({
                event: 'mouse',
                positionX: right
              });
            }
          } else
            this.handleMoveLabelToRight({
              event: 'mouse',
              positionX: e.clientX
            });
        }
        this.prevMousePosition = e.clientX;
      }
    },

    handleMouseUpEvents(e) {
      e.preventDefault();
      if (this.moveLabel) {
        document.removeEventListener('mousemove', this.handleMouseMoveEvents);
      }
    },

    handleClickActiveInterval(currStepIndex, currIntervalIndex) {
      this.currentStepId = `step-${currStepIndex}-painter`;
      const currentStepPainterDiv = document.getElementById(
        `step-${currStepIndex}-painter`
      );
      this.currentStepToPaint = currentStepPainterDiv.getBoundingClientRect();
      const currentInterval = document.getElementById(
        `painter-step-${currStepIndex}-interval-${currIntervalIndex}`
      );
      this.currentIntervalToPaint = currentInterval;
      currentStepPainterDiv.addEventListener('mouseleave', () => {
        document.removeEventListener('mousemove', this.handleMouseMoveEvents);
      });
    },

    handleKeyUpEvents(evt) {
      // clear all labels
      if (evt.key === 'c') {
        evt.preventDefault();
        this.isClearLabels = true;
      }
      // save annotations
      else if (evt.key === 'p') {
        if (!this.videoInfo?.isProtectedVideo) {
          evt.preventDefault();
          this.handleSave();
        }
      }
    },

    handleKeyDownEvents(evt) {
      // toggle substep
      if (evt.code === 'Space' && !this.isVisualize) {
        evt.preventDefault();
        if (this.stepToPaint && this.selectedStep) {
          this.stepToPaint = null;
          this.editLabel = this.editLabel ? false : this.editLabel;
          this.moveLabel = this.moveLabel ? false : this.moveLabel;
          this.stepToMove = null;
        } else {
          this.setIntervalIndex();
          this.selectToPaint();
        }
      }
      // switch substep prev/next
      else if ((evt.key === 'w' || evt.key === 's') && !this.isVisualize) {
        evt.preventDefault();
        this.switchSubStep(evt.key);
      } else if (evt.code === 'ArrowRight' && this.moveLabel) {
        this.changeSliderToRight(1);
      } else if (evt.code === 'ArrowLeft' && this.moveLabel) {
        this.changeSliderToLeft(1);
      }
      // change frame value
      else if (
        evt.key === 'a' ||
        evt.key === 'A' ||
        evt.key === 'd' ||
        evt.key === 'D'
      ) {
        evt.preventDefault();
        this.moveLabel
          ? this.moveLabelByKeys(evt.key)
          : this.changeFrameValue(evt.key);
      } else if (
        evt.key === 'i' ||
        evt.key === 'j' ||
        evt.key === 'k' ||
        evt.key === 'l'
      ) {
        evt.preventDefault();
        this.scrollbarNavigate(evt.key);
      }
    },

    setSliderOnDirectionChanged(direction) {
      const temp = [...this.substepsList];
      const [index] = this.stepToPaint.split('-');
      const { start, end } = temp[index]['interval'][this.intervalIndex];
      if (direction === 'right') {
        this.currentSliderVal = (end * this.totalFrames) / 100;
      } else if (direction === 'left') {
        this.currentSliderVal = (start * this.totalFrames) / 100;
      }
    },

    handleMoveLabelToLeft({ event = 'key', positionX, frameValue }) {
      let newStart = null,
        newSliderValue = null;
      const {
        temp,
        index,
        currentInterval,
        neighbourInterval
      } = this.getIntervalsData('previous');
      const { start, end } = currentInterval;
      if (event === 'mouse') {
        const { width, left } = this.currentStepToPaint;
        newSliderValue = ((positionX - left) / width) * this.totalFrames;
        newStart = (newSliderValue / this.totalFrames) * 100;
      } else {
        newSliderValue = this.currentSliderVal - parseInt(frameValue);
        newStart = (newSliderValue / this.totalFrames) * 100;
      }
      if (neighbourInterval && newStart <= neighbourInterval.end)
        return this.handlePromptWhenLabelMerged();
      this.currentSliderVal = newSliderValue;
      temp[index]['interval'][this.intervalIndex] = {
        start: newStart,
        end: end - (start - newStart)
      };
      const parentIndex = temp[index].parent;
      this.updateStepPainter(temp, parentIndex);
      this.prevStepsToPaint = null;
    },

    handleMoveLabelToRight({ event = 'key', positionX, frameValue }) {
      let newEnd = null,
        newSliderValue = null;
      const {
        temp,
        index,
        currentInterval,
        neighbourInterval
      } = this.getIntervalsData('next');
      const { start, end } = currentInterval;
      if (event === 'mouse') {
        const { width, left } = this.currentStepToPaint;
        newSliderValue = ((positionX - left) / width) * this.totalFrames;
        newEnd = (newSliderValue / this.totalFrames) * 100;
      } else {
        newSliderValue = this.currentSliderVal + parseInt(frameValue);
        newEnd = (newSliderValue / this.totalFrames) * 100;
      }
      if (neighbourInterval && newEnd >= neighbourInterval.start)
        return this.handlePromptWhenLabelMerged();
      this.currentSliderVal = newSliderValue;
      temp[index]['interval'][this.intervalIndex] = {
        start: start + (newEnd - end),
        end: newEnd
      };
      const parentIndex = temp[index].parent;
      this.updateStepPainter(temp, parentIndex);
      this.prevStepsToPaint = null;
    },

    changeSliderToRight(frame) {
      if (!this.isSliderMoveRightSet) {
        this.isSliderMoveLeftSet = false;
        this.isSliderMoveRightSet = true;
        this.setSliderOnDirectionChanged('right');
      } else this.handleMoveLabelToRight({ frameValue: frame });
    },

    changeSliderToLeft(frame) {
      if (!this.isSliderMoveLeftSet) {
        this.isSliderMoveRightSet = false;
        this.isSliderMoveLeftSet = true;
        this.setSliderOnDirectionChanged('left');
      } else this.handleMoveLabelToLeft({ frameValue: frame });
    },

    handleOutsideClick() {
      this.showContextMenu = false;
      this.stepToRemove = null;
      this.stepToMove = null;
    },

    getIntervalsData(neighbour = 'next') {
      const temp = [...this.substepsList];
      const [index] = this.stepToPaint.split('-');
      const currentInterval = temp[index]['interval'][this.intervalIndex];
      let neighbourInterval = null;
      if (neighbour === 'next')
        neighbourInterval = temp[index]?.interval[this.intervalIndex + 1];
      else if (neighbour === 'previous')
        neighbourInterval = temp[index]?.interval[this.intervalIndex - 1];

      return {
        currentInterval,
        neighbourInterval,
        temp,
        index
      };
    },

    handlePromptWhenLabelMerged() {
      this.toast.dismiss(this.toastId);
      this.toastId = this.toast.info('Two labels can not be merged!');
    },

    handleRemoveLabel(stepIndex, intervalIndex) {
      const temp = [...this.substepsList];
      temp[stepIndex].interval = temp[stepIndex].interval.filter(
        (_, index) => index !== intervalIndex
      );
      this.substepsList = temp;
      this.updateStepPainter(this.substepsList, temp[stepIndex]['parent']);
      // this.forceUpdate(this.substepsList);
      // this.selectedStep = null;
      this.stepToPaint = null;
    },

    handleClearLabels() {
      const step = this.selectedStep;
      this.clearAllLabels();
      this.selectedStep = step;
      if (this.$refs.zoomSliderContainer.$el) {
        const scrollMaxX =
          this.$refs.zoomSliderContainer.$el.scrollMaxX ||
          this.$refs.zoomSliderContainer.$el.scrollWidth -
            this.$refs.zoomSliderContainer.$el.clientWidth;
        const horizontalScrollStep = scrollMaxX / this.totalFrames;
        this.$refs.zoomSliderContainer.$el.scrollLeft =
          horizontalScrollStep * this.currentSliderVal;
      }
      this.isClearLabels = false;
    },

    handleContextMenu(e, { stepIndex, intervalIndex }) {
      // if (roles.labeler !== this.role) return;

      this.stepToRemove = { stepIndex, intervalIndex };

      const { x, y } = e;
      const { context, mainContainer } = this.$refs;
      const { x: mainX, y: mainY } = mainContainer.getClientRects()[0];
      context.style.top = y - mainY - 60 + 'px';
      context.style.left = x - mainX - 150 + 'px';

      this.showContextMenu = true;
    },

    handleScroll(e) {
      const { scrollTop } = e.target;
      const target = this.$refs[e.target.getAttribute('data-name')];
      target.scrollTop = scrollTop;
    },

    switchSubStep(direction) {
      if (Object.keys(this.substepsList).length === 0) return;

      var index = null;
      if (this.selectedStep === null) {
        index = 0;
        direction = 's'; // next substep
      } else index = Number(this.selectedStep?.split('-')[0]);

      while (true) {
        if (!this.$refs.stepContainer) {
          break;
        }

        const scrollMaxY =
          this.$refs.stepContainer.scrollMaxY ||
          this.$refs.stepContainer.scrollHeight -
            this.$refs.stepContainer.clientHeight;
        const verticalScrollStep =
          scrollMaxY / (Object.keys(this.substepsList).length - 1);

        // next substep
        if (direction === 's') {
          index += 1;
          this.$refs.stepContainer.scrollTop += Math.ceil(verticalScrollStep);
        }
        // prev substep
        else if (direction === 'w') {
          index -= 1;
          this.$refs.stepContainer.scrollTop -= verticalScrollStep;
        } else {
          break;
        }

        if (index < 0 || index >= this.substepsList.length) {
          break;
        }
        this.$refs.stepContainer.scrollTop = verticalScrollStep * index;
        var step = this.substepsList[index];
        if (step.type === 'sub-step') {
          this.selectedStep = `${index}-${step.name}`;
          this.stepToPaint = null;
          break;
        }
      }
    },

    changeFrameValue(key) {
      if (!this.$refs.zoomSliderContainer.$el) return;

      if (key === 'd') {
        this.currentSliderVal = this.currentSliderVal + 1;
      } else if (key === 'a') {
        this.currentSliderVal = this.currentSliderVal - 1;
      } else if (key === 'D') {
        this.currentSliderVal =
          this.currentSliderVal + Number(this.frameStepSize);
      } else if (key === 'A') {
        this.currentSliderVal =
          this.currentSliderVal - Number(this.frameStepSize);
      }
      this.handleHorizontalScroll();
    },

    moveLabelByKeys(key) {
      if (key === 'd') {
        this.changeSliderToRight(1);
      } else if (key === 'a') {
        this.changeSliderToLeft(1);
      } else if (key === 'D') {
        this.changeSliderToRight(10);
      } else if (key === 'A') {
        this.changeSliderToLeft(10);
      }
    },

    handleHorizontalScroll() {
      if (!this.$refs.zoomSliderContainer.$el) return;
      const scrollMaxX =
        this.$refs.zoomSliderContainer.$el.scrollMaxX ||
        this.$refs.zoomSliderContainer.$el.scrollWidth -
          this.$refs.zoomSliderContainer.$el.clientWidth;
      const horizontalScrollStep = scrollMaxX / this.totalFrames;

      this.$refs.zoomSliderContainer.$el.scrollLeft =
        horizontalScrollStep * this.currentSliderVal;
    },

    scrollbarNavigate(key) {
      if (!this.$refs.stepContainer) {
        return;
      }
      const scrollMaxX =
        this.$refs.zoomSliderContainer.$el.scrollMaxX ||
        this.$refs.zoomSliderContainer.$el.scrollWidth -
          this.$refs.zoomSliderContainer.$el.clientWidth;
      const scrollMaxY =
        this.$refs.stepContainer.scrollMaxY ||
        this.$refs.stepContainer.scrollHeight -
          this.$refs.stepContainer.clientHeight;

      if (key === 'i') {
        this.$refs.stepContainer.scrollTop -= scrollMaxY / 20;
      } else if (key === 'k') {
        this.$refs.stepContainer.scrollTop += scrollMaxY / 20;
      } else if (key === 'j') {
        this.$refs.zoomSliderContainer.$el.scrollLeft -= scrollMaxX / 20;
      } else if (key === 'l') {
        this.$refs.zoomSliderContainer.$el.scrollLeft += scrollMaxX / 20;
      }
    },

    clearCurrentLabel() {
      if (!this.selectedStep) return;
      const index = this.selectedStep?.split('-')[0];
      this.selectedStep = null;
      this.stepToPaint = null;
      this.substepsList[index].interval = [];
      const parentIndex = this.substepsList[index].parent;
      this.updateStepPainter([...this.substepsList], parentIndex);
    },

    clearAllLabels() {
      this.selectedStep = null;
      this.stepToPaint = null;
      this.currentSliderVal = 0;
      this.substepsList = this.getSubStepsList(this.stepsList);
    },

    updateStepPainter(temp, parentIndex) {
      const parentInterval = this.getInterval(
        temp[parentIndex].childIndices,
        temp
      );
      temp[parentIndex].interval = [...parentInterval];
      this.substepsList = temp;
    },

    getPrevEnd(step, intervalIndex) {
      if (step.type === 'step') return 0;

      let idx = intervalIndex - 1 < 0 ? 0 : intervalIndex - 1;
      return step.interval[idx].end;
    },

    addStartEndSteps(steps) {
      const [start, end] = this.startEndSteps;

      const canAdd =
        !this.isVisualize &&
        steps[0]?.name !== start.toUpperCase() &&
        roles.labeler === this.role;

      if (canAdd) {
        steps.unshift({
          name: start.toUpperCase(),
          substeps: [start]
        });
        steps.push({
          name: end.toUpperCase(),
          substeps: [end]
        });
      }
      return steps;
    },

    getSubStepsList(steps) {
      // const tempsteps = this.addStartEndSteps(steps);

      const list = [];
      let parentIndex, childIndex;
      steps?.forEach(step => {
        parentIndex =
          list.push({
            name: step.name,
            type: 'step',
            interval: [],
            childIndices: []
          }) - 1;
        const childIndices = [];
        step.substeps.forEach(substep => {
          childIndex =
            list.push({
              name: substep,
              type: 'sub-step',
              interval: [],
              parent: parentIndex
            }) - 1;
          childIndices.push(childIndex);
        });
        list[parentIndex]['childIndices'] = childIndices;
      });

      return list;
    },

    removeFirstAndLastStep() {
      const fixedSteps = ['123ABC', '456ZXC'];
      const temp = { ...this.prevMetaData };
      fixedSteps.forEach(step => {
        delete temp[step];
      });
      return temp;
    },

    fillPainter() {
      if (!this.prevMetaData) return;
      const tempPrevMetaData =
        roles.labeler !== this.role
          ? this.removeFirstAndLastStep()
          : this.prevMetaData;

      // const values = Object.values(this.prevMetaData);
      const values = Object.values(tempPrevMetaData);
      const temp = [];
      let parentIndex, childIndices;
      values.forEach(step => {
        childIndices = [];
        Object.values(step).forEach(substep => {
          const obj = {};
          if (substep.step === substep.labelText) {
            obj['name'] = substep.step;
            obj['type'] = 'step';
            obj['interval'] = substep.currentFrameNumber[0]
              ? [
                  {
                    start: this.getPercent(substep.startFrame[0]),
                    end: this.getPercent(substep.currentFrameNumber[0])
                  }
                ]
              : [
                  {
                    start: this.getPercent(substep.startFrame),
                    end: this.getPercent(substep.currentFrameNumber)
                  }
                ];

            parentIndex = temp.push(obj) - 1;
          } else {
            obj['name'] = substep.labelText;
            obj['type'] = 'sub-step';
            obj['parent'] = parentIndex;
            obj['interval'] = substep.startFrame.map
              ? substep.startFrame
                  .map((el, idx) => ({
                    start: this.getPercent(el),
                    end: this.getPercent(substep.currentFrameNumber[idx])
                  }))
                  .filter(el => el.end !== 0 && el.start !== el.end)
              : [
                  {
                    start: this.getPercent(substep.startFrame),
                    end: this.getPercent(substep.currentFrameNumber)
                  }
                ];
            const childIndex = temp.push(obj) - 1;
            childIndices.push(childIndex);
          }
        });
        temp[parentIndex]['childIndices'] = childIndices;
        childIndices = [];
      });
      this.substepsList = [...temp];
      if (!this.prevSubstepsList)
        this.prevSubstepsList = JSON.parse(JSON.stringify(temp));
    },

    forceUpdate(temp) {
      temp.forEach((el, idx) => {
        if (el.type === 'step') {
          this.updateStepPainter(this.substepsList, idx);
        }
      });
    },

    showVisualization() {
      console.log('showVisualization');
      const temp = [...this.substepsList];
      let i = 0;
      temp.forEach(el => {
        if (el.type === 'sub-step') {
          el['interval'] =
            this.stepsToVisualize[i]?.map(({ start, end }) => ({
              start: (start / this.totalFrames) * 100,
              end: (end / this.totalFrames) * 100
            })) || [];
          i++;
        }
      });
      this.substepsList = [...temp];

      temp.forEach((el, idx) => {
        if (el.type === 'step') {
          this.updateStepPainter(this.substepsList, idx);
        }
      });
    },

    handleSelect(step, index) {
      if (step.type === 'step') return;

      const tempSelected = `${index}-${step.name}`;
      if (this.selectedStep !== tempSelected) this.stepToPaint = null;
      this.selectedStep = tempSelected;
    },

    async handleSaveProgress() {
      const json = this.getJson();
      const isEmptyJson = this.getIsJsonEmpty(json);
      let response;
      console.log('json ->', json);
      console.log('isEmptyJson ->', isEmptyJson);
      if (!isEmptyJson) {
        response = await this.saveMetaFile(json);
        this.createOrUpdateSubStepProgress(json);
        const data = Object.values(response.data)[0][0];
        const { labelled } = data;
        await this.updateTaskRecord(
          { labelled: labelled },
          this.videoInfo,
          false
        );
      }
    },

    async handleSave() {
      this.setIsSavingAnnoation(true);
      const json = this.getJson();
      let response;
      const isEmptyJson = this.getIsJsonEmpty(json);
      if (isEmptyJson) {
        response = await this.deleteMetaFileFromS3();
        await this.updateTaskRecord({ labelled: 0 }, this.videoInfo, false);
      } else response = await this.saveMetaFile(json);

      this.setIsSavingAnnoation(false);

      if (response === 'error') return;
      this.createOrUpdateSubStepProgress(json);
      this.createAnnotationSession();
      this.toast.success(
        'Successfully Labelled ' + this.videoInfo?.fileName + '!',
        { timeout: 3000, position: POSITION.TOP_LEFT }
      );

      this.$emit('onSave', {
        data: Object.values(response.data)[0][0],
        isEmptyJson
      });
    },

    async createOrUpdateSubStepProgress(json) {
      let payload = this.getPayloadForStepProgress(json);
      await httpClient.post(
        'organization/task/substep_labeling_progress_create_list/',
        payload,
        false,
        false,
        false
      );
    },

    getPayloadForStepProgress(json) {
      let payload = [];
      Object.keys(json).forEach(step => {
        Object.keys(json[step])
          .slice(1)
          .forEach(substep => {
            const startFrame = json[step][substep]['startFrame'];
            const currentFrameNumber =
              json[step][substep]['currentFrameNumber'];
            const total_time =
              startFrame
                .map((num, idx) => currentFrameNumber[idx] - num)
                .reduce((a, b) => a + b, 0) / this.videoInfo?.fps;
            payload.push({
              substep_name: substep,
              total_time: total_time,
              task_record_id: this.videoInfo?.id,
              task_id: this.taskId
            });
          });
      });
      return payload;
    },

    async createAnnotationSession() {
      const payload = {
        start_time: this.startTime,
        end_time: new Date().toISOString(),
        task_type: 'video',
        user: localStorage.getItem('id'),
        task: this.taskId
      };

      await httpClient.post(
        'cloud_source_labeling/create_anotation_session/',
        payload,
        false,
        false,
        false
      );
    },

    deleteMetaFileFromS3() {
      return new Promise(async (resolve, _) => {
        const payload = {
          bucket: `${this.organization}-training`,
          file_path: `${this.taskName.trim()}/Meta/${
            this.videoInfo?.fileName
          }.json`,
          organization: this.organization
        };
        const [error, data] = await S3Service.deleteFile(payload, false);
        resolve(error || data);
      });
    },

    saveMetaFile(json) {
      return new Promise(async (resolve, _) => {
        var blob = new Blob([JSON.stringify(json)], {
          type: 'text/json;charset=utf-8'
        });
        var formData = new FormData();
        formData.append('file', blob, this.videoInfo?.fileName + '.json');
        formData.append('task_id', this.taskId);
        formData.append('Validation', 'False');
        const response = await httpClient.upload(
          'organization/task/upload/',
          formData,
          this
        );
        resolve(response);
      });
    },

    getIsJsonEmpty(json) {
      return Object.values(json).every(step => {
        return Object.values(step).every(
          substep =>
            !substep['currentFrameNumber'][0] && !substep['startFrame'][0]
        );
      });
    },

    getJson() {
      const keys = this.getStepkeys();
      const json = {};
      keys.forEach(step => {
        const startFrame = this.getFrame(step.interval[0]?.start);
        const currentFrameNumber = this.getFrame(step.interval[0]?.end);

        if (this.isCycleUnlabeled(step.name, startFrame, currentFrameNumber))
          return;

        const obj = this.getStepDict(step, startFrame, currentFrameNumber);

        const substeps = this.substepsList.filter((_, idx) =>
          step.childIndices.includes(idx)
        );
        substeps.forEach(
          substep => (obj[substep.name] = this.getSubstepDict(substep, step))
        );

        json[step.name] = obj;
      });

      return json;
    },

    getStepkeys() {
      return this.substepsList
        .map((el, idx) => ({ ...el, idx }))
        .filter(el => el.type === 'step');
    },

    isCycleUnlabeled(stepName, startFrame, currentFrameNumber) {
      return (
        ['123ABC', '456ZXC'].includes(stepName) &&
        !startFrame &&
        !currentFrameNumber
      );
    },

    getStepDict(step, startFrame, currentFrameNumber) {
      return {
        [step.name]: {
          typeP: 4,
          step: step.name,
          labelText: step.name,
          startFrame: [startFrame],
          currentFrameNumber: [currentFrameNumber],
          min: [this.getFrame(step.interval[0]?.start)],
          max: [this.getFrame(step.interval[0]?.end)],
          isLabled: !!step.interval.length,
          secondMax: 0,
          isBeingLabled: false
        }
      };
    },

    getSubstepDict(substep, step) {
      return {
        typeP: 3,
        step: step.name,
        labelText: substep.name,
        startFrame: substep.interval.map(e => this.getFrame(e.start)),
        currentFrameNumber: substep.interval.map(e => this.getFrame(e.end)),
        min: substep.interval.map(e => Number.MAX_SAFE_INTEGER),
        max: substep.interval.map(e => 0),
        isLabled: !!substep.interval.length
      };
    },

    getFrame(percent) {
      if (percent === undefined) {
        return 0;
      }
      return Math.ceil((percent * this.totalFrames) / 100);
    },

    getPercent(frame) {
      return (frame / this.totalFrames) * 100;
    },

    selectToPaint() {
      if (!this.selectedStep) return;
      this.removePrevSelectedStep();
      this.currentStepStart = this.currentSliderVal;
      const [index] = this.selectedStep?.split('-');
      if (!this.canLabel(index)) {
        this.toast.dismiss(this.toastId);
        this.toastId = this.toast.info(
          'Please remove the label first in order to label again!'
        );
        return;
      }
      this.stepToPaint = this.selectedStep;

      const intervalToBeAdded = this.substepsList[index].interval[
        this.intervalIndex
      ];

      if (
        intervalToBeAdded &&
        intervalToBeAdded?.start === intervalToBeAdded?.end
      )
        return;

      const obj = {
        start: this.currentSliderPercent,
        end: this.currentSliderPercent
      };
      this.prevStepsToPaint = this.selectedStep;
      let tempInterval = this.substepsList[index].interval;
      tempInterval = [...tempInterval, obj].sort((a, b) => a.start - b.start);
      this.substepsList[index].interval = tempInterval;
    },

    canLabel(stepIndex) {
      const { interval } = this.substepsList[stepIndex];
      const currentSliderVal = this.currentSliderPercent;
      return interval.every(
        ({ start, end }) => currentSliderVal < start || end < currentSliderVal
      );
    },

    removePrevSelectedStep() {
      if (!this.prevStepsToPaint) return;
      const [index] = this.prevStepsToPaint?.split('-');
      const temp = [...this.substepsList];
      temp[index].interval.pop();
      this.substepsList = temp;
    },

    setIntervalIndex() {
      if (!this.selectedStep) return;

      const [index] = this.selectedStep?.split('-');
      const { interval } = this.substepsList[index];
      const currentPercent = this.getPercent(this.currentSliderVal);
      for (let idx = 0; idx < interval.length; idx++) {
        const { start } = interval[idx];
        if (currentPercent < start) {
          this.intervalIndex = idx;
          return;
        }
      }
      this.intervalIndex = interval.length;
    },

    getInterval(childIndices, arr) {
      let start = Number.MAX_SAFE_INTEGER;
      let end = Number.MIN_SAFE_INTEGER;
      childIndices.forEach(el => {
        const min = Math.min(...arr[el].interval.map(el => el.start));
        const max = Math.max(...arr[el].interval.map(el => el.end));
        // const min = this.getMin(arr[el].interval.map((el) => el.start));
        // const max = this.getMax(arr[el].interval.map((el) => el.end));
        start = min < start ? min : start;
        end = max > end ? max : end;
      });
      if (start === Number.MAX_SAFE_INTEGER || end === Number.MIN_SAFE_INTEGER)
        return [];
      return [{ start, end }];
    },

    getMax(arr) {
      if (arr?.length === 0) return 0;
      let max = Number.MIN_SAFE_INTEGER;
      arr.forEach(el => {
        max = max < el ? el : max;
      });
      return max;
    },

    getMin(arr) {
      if (arr?.length === 0) return 0;
      let min = Number.MAX_SAFE_INTEGER;
      arr.forEach(el => {
        min = min > el ? el : min;
      });
      return min;
    },

    getStepElementClasses(step, index) {
      const isStep = step.type === 'step';
      const isSubStep = step.type === 'sub-step';
      const isSelectedStep = this.selectedStep === `${index}-${step.name}`;
      const isOverlapped = this.isOverlapped(index, step.type);
      const isBarcodedStep = this.isBarcodedStep(step.name);
      const isStepToBePainted = this.stepToPaint === `${index}-${step.name}`;

      return {
        'list-group-item list-group-item-primary': isStep,
        'disable-click-event': isStep || this.isVisualize,
        'cursor-pointer': isSubStep,
        'pl-3': isSubStep,
        'font-weight-bold': isSelectedStep,
        'item-highlight': isOverlapped,
        'list-group-item list-group-item-success': isStepToBePainted
        // 'text-muted': isBarcodedStep,
        // 'cursor-default': isBarcodedStep,
      };
    }
  }
};
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
}
.border {
  border: 1px solid;
}

.slider {
  padding-right: 1em;
  /* outline: none;
  width: 100%; */
}
.cursor-pointer {
  cursor: pointer;
}
.progress-container {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  position: relative;
  line-height: 0.8;
}

.slider-div {
  overflow-x: scroll;
  overflow-y: hidden;
}

.of-hidden {
  overflow: hidden !important;
}

.painter {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  /* max-height: 200px !important; */
}

.action-button {
  margin-top: 0.5% !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.disable-click-event {
  pointer-events: none;
}

.context {
  /* height: 60px; */
  position: absolute;
  z-index: 100000;
}

.list-group-item:hover {
  background: ghostwhite;
}

.item-highlight {
  /* background: #d4d4f5 !important; */
  font-weight: bold;
}

.vline {
  height: 100%;
  width: 1px;
  background: gray;
  position: absolute;
  overflow: hidden;
  z-index: 5;
}

/* .annotate-bottom-left {
  height: 30vh;
  overflow-y: hidden;
  overflow-x: auto;
} */
/* .annotate-bottom-slider {
  height: 30vh;
  overflow-y: auto;
} */

.ant-slider > .ant-slider-rail {
  height: 10px;
  background: #d1d5db;
}
</style>
