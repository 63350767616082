<template>
  <div
    class="progress-container"
    :style="{ width: whiteWidth + '% !important' }"
  />
  <a-dropdown placement="top" trigger="contextmenu">
    <div
      :id="'painter-step-' + step.name + '-interval-' + intervalIndex"
      class="progress-container labelled-step"
      :class="{
        'list-group-item list-group-item-primary disable-click':
          !isSubstep || isVisualize,
        'list-group-item list-group-item-success': isSubstep,
        'item-highlight': isSeleted,
        'item-highlight': (isOverlapped && isSubstep) || isIntervalSelected,
        'interval-selected': isIntervalSelected && labelMoving
      }"
      :style="{
        width: interval.end - interval.start + '% !important'
      }"
      @mousedown="handleClickInterval"
    />
    <template #overlay>
      <a-menu
        :id="
          'painter-step-' + stepIndex + '-interval-' + intervalIndex + '-menu'
        "
      >
        <a-menu-item
          @click="$emit('handleRemoveLabel', stepIndex, intervalIndex)"
        >
          Remove label
        </a-menu-item>
        <a-menu-item @click="clickEditLabel('start')">
          Edit Start
        </a-menu-item>
        <a-menu-item @click="clickEditLabel('end')">
          Edit End
        </a-menu-item>
        <!-- <a-menu-item @click="clickMoveLabel()">Move Label</a-menu-item> -->
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
export default {
  props: [
    'interval',
    'intervalIndex',
    'step',
    'stepIndex',
    'prevEnd',
    'stepToRemove',
    'stepToMove',
    'currentSliderPercent',
    'totalFrames',
    'isVisualize',
    'labelMoving'
  ],
  emits: [
    'handleContextMenu',
    'handleRemoveLabel',
    'handleSelect',
    'handleEditLabel',
    'handleMoveLabel',
    'handleClickCurrentInterval'
  ],
  data: function() {
    return {
      isIntervalSelected: false,
      log: console.log
    };
  },
  computed: {
    whiteWidth() {
      const width =
        this.interval.start - this.prevEnd < 0
          ? this.interval.start
          : this.interval.start - this.prevEnd;

      return width;
    },

    isSubstep() {
      return this.step.type !== 'step';
    },

    isSeleted() {
      if (!this.stepToRemove) return false;
      const { intervalIndex, stepIndex } = this.stepToRemove;
      return (
        this.intervalIndex === intervalIndex && this.stepIndex === stepIndex
      );
    },

    isOverlapped() {
      const { start, end } = this.interval;
      const { currentSliderPercent } = this;
      return start <= currentSliderPercent && currentSliderPercent <= end;
    }
  },
  watch: {
    stepToMove(val) {
      if (this.stepToMove) {
        const { intervalIndex, stepIndex } = val;
        this.isIntervalSelected =
          this.intervalIndex === intervalIndex && this.stepIndex === stepIndex;
      }
    }
  },

  methods: {
    handleContextMenu(e, step) {
      this.$emit('handleContextMenu', e, step);
    },
    clickEditLabel(position) {
      let editPoint = null;
      if (position === 'start') {
        const { start } = this.interval;
        editPoint = (start * this.totalFrames) / 100;
      } else if (position === 'end') {
        const { end } = this.interval;
        editPoint = (end * this.totalFrames) / 100;
      }
      this.$emit('handleSelect', this.step, this.stepIndex);
      this.$emit('handleEditLabel', editPoint, this.intervalIndex, position);
    },
    clickMoveLabel() {
      this.$emit('handleSelect', this.step, this.stepIndex);
      this.$emit('handleMoveLabel', this.stepIndex, this.intervalIndex);
    },
    handleClickInterval() {
      if (this.isIntervalSelected && this.labelMoving) {
        this.$emit(
          'handleClickCurrentInterval',
          this.stepIndex,
          this.intervalIndex
        );
      }
    }
  }
};
</script>
<style scoped>
.progress-container {
  height: 20px;
}
html,
body {
  margin: 0;
  padding: 0;
}

.interval-selected {
  border: 2px dashed #0f5132 !important;
  position: relative;
}
.list-group-item-success:hover {
  background: #9febc9;
}
.item-highlight {
  background: #9febc9 !important;
}
</style>
