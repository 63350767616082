<template>
  <div id="annoatate-video-labelling-instruction">
    <a-descriptions
      title="Annotation Instructions"
      :colon="false"
      size="small"
      :contentStyle="textStyle"
      v-if="!isVisualize"
    >
      <a-descriptions-item label="1." span="3">
        Click on a substep.
      </a-descriptions-item>
      <a-descriptions-item label="2." span="3">
        Adjust Slider to the Frame/Time you want to start labelling a step and
        press space.
      </a-descriptions-item>
      <a-descriptions-item label="3." span="3">
        When done labelling a step press space again or click another substep.
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions
      title="Moving Label Instructions"
      :colon="false"
      size="small"
      :contentStyle="textStyle"
    >
      <a-descriptions-item label="1." span="3">
        Left click on a label and select Move.
      </a-descriptions-item>
      <a-descriptions-item label="2." span="3">
        Click on a label and start moving mouse in left or right direction and
        release it when done.
      </a-descriptions-item>
      <a-descriptions-item label="3." span="3">
        When done moving a label press space or select another label.
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
<script>
export default {
  data() {
    return {
      textStyle: {
        fontSize: '12.8px',
      },
    };
  },
};
</script>
<style>
.fw-b {
  font-weight: bold !important;
}

.ant-descriptions-header {
  margin-bottom: 6px !important;
}
</style>
