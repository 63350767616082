<template>
  <a-row :gutter="[4, 1]" v-if="!loading" class="overflow-auto h-100">
    <a-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="15"
      :xl="15"
      class="annotate-top-col"
    >
      <a-card class="h-100" size="small">
        <a-row :gutter="[4, 0]">
          <a-col
            :xs="24"
            :sm="24"
            :md="7"
            :lg="7"
            :xl="7"
            id="annotate-video-info-box"
            ><video-info
              :videoInfo="video"
              :videoMetaData="videoMetaData"
              :totalFrames="totalFrames"
              :isVisualize="isVisualize"
            >
            </video-info>
            <a-list
              size="small"
              bordered
              :data-source="saveFrames"
              class="mt-1 mr-1"
              v-if="saveFrames.length"
              style="max-width: 230px; height: 210px; overflow-y: auto"
              id="save-frames-list"
            >
              <template #header>
                <a-typography-text strong>
                  Saved Frames :
                  {{ saveFrames.length }}
                </a-typography-text>
              </template>
              <template #renderItem="{ item, index }">
                <a-list-item
                  :id="'save-frame-li' + index"
                  class="d-flex justify-content-between"
                >
                  Frame: {{ item }}
                  <template #actions>
                    <delete-outlined
                      class="text-danger"
                      @click="removeFrame(item)"
                      :id="'frame-delete-' + index"
                    />
                  </template>
                </a-list-item>
              </template>
            </a-list>
          </a-col>
          <a-col
            :xs="24"
            :sm="24"
            :md="9"
            :lg="9"
            :xl="9"
            id="annotate-video-inst-box"
          >
            <labelling-instruction></labelling-instruction>
            <a-button
              v-if="!loading && !isVisualize"
              @click="handleAddSaveFrame"
              id="save-frame-btn"
              >Save Frame
            </a-button>
          </a-col>
          <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="pl-4">
            <keyboard-keys-info
              :isVisualize="isVisualize"
              :zoomIn="zoomIn"
              :totalFrames="totalFrames"
              @changeZoom="(val) => (zoomIn = val)"
            ></keyboard-keys-info>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="9" :xl="9" class="d-flex pt-3">
      <VideoPlayer
        :style="{ width: '10px' }"
        v-show="false"
        ref="videoPlayer"
      />
      <video
        v-if="video.availableLocally"
        ref="video"
        muted
        class="m-auto"
        style="width: 33vw"
        :src="video?.localURL || video?.fileURL"
        id="annotate-video-local"
      ></video>
      <video
        v-else
        ref="video"
        muted
        class="m-auto"
        style="width: 90vw"
        :src="video?.localURL || video?.fileURL"
        id="annotate-video-local"
      ></video>
      <!--      <video id="video" autoplay="true" playsinline="true" class="w-100"></video>-->
    </a-col>
  </a-row>
  <a-row class="mt-3" style="height: 30vh" v-if="!loading">
    <a-col :span="24" class="h-100" id="annotate-video-labelling-box">
      <labelling-tool
        ref="labellingComponent"
        :stepsList="stepsList"
        :prevMetaData="prevMetaData"
        :stepsToVisualize="stepsToVisualize"
        :videoInfo="video"
        :taskId="taskId"
        :taskName="taskName"
        :totalFrames="totalFrames"
        :frame="currentFrame"
        :isVisualize="isVisualize"
        :zoomIn="zoomIn"
        :organization="organization"
        :updateTaskRecord="updateTaskRecord"
        :stepsTranslationMapping="stepsTranslationMapping"
        :stepsToIndexMapping="stepsToIndexMapping"
        :barcodedSteps="barcodedSteps"
        @frameChange="handleFrameChange"
        @onSave="(data) => $emit('onSave', data)"
      ></labelling-tool>
    </a-col>
  </a-row>
</template>
<script>
import { getStepToIndexMapping, getTaskNameAndProcess } from '@/utils/task.js';
import { DeleteOutlined } from '@ant-design/icons-vue';
import httpClient from 'src/service/httpClient';
import TaskRecordService from 'src/services/taskRecord';
import { mapActions, mapGetters } from 'vuex';
import { prodHostUrl } from '../../../../service/http/httpRequestConfig';
import getNumberOfFrames from '../../../shared/Helpers/getNumberOfFrames';
import getStepsList from '../../../shared/Helpers/getStepsList';
import getTranslatedSteps from '../../../shared/Helpers/getTranslatedSteps.js';
import LabellingInstruction from './Instrunction.vue';
import KeyboardKeysInfo from './KeyboardKeysInfo.vue';
import LabellingTool from './Labelling.vue';
import VideoPlayer from './Video.vue';
import VideoInfo from './VideoInfo.vue';

export default {
  props: {
    video: {},
    taskId: {},
    isVisualize: { default: false },
    prevMetaData: {},
    loading: {},
    organization: {},
    updateTaskRecord: Function,
  },
  inject: ['toast'],
  emits: ['onSave', 'stopLoading', 'updateLoading', 'closeModal'],
  components: {
    VideoInfo,
    VideoPlayer,
    LabellingTool,
    LabellingInstruction,
    KeyboardKeysInfo,
    DeleteOutlined,
  },
  data: function () {
    return {
      taskName: '',
      stepsList: [],
      stepsToVisualize: {},
      currentFrame: 0,
      totalFrames: Number.MAX_SAFE_INTEGER,
      videoMetaData: null,
      zoomIn: false,
      peerConnection: null,
      connection: null,
      value: 0,
      stunCheckbox: true,
      offer: null,
      config: [{ sdpSemantics: 'unified-plan' }],
      rtcInteval: null,
      saveFrames: [],
      stepsTranslationMapping: {},
      predsToStepsMap: {},
      stepsToIndexMapping: {},
      barcodedSteps: [],
    };
  },
  computed: {
    ...mapGetters([
      'role',
      'webSocketConnection',
      'taskDetails',
      'taskStepsCount',
      'translationDetails',
      'translationLanguage',
      'selectedLang',
      'associateBarcodes',
    ]),
  },

  created() {
    window.onbeforeunload = () => '';
  },

  mounted() {
    if (!this.taskDetails) return;
    this.setTaskDetails(this.taskDetails, true);
    this.setStepTranslations(this.selectedLang);
    this.getBarcodedSteps();
    // if (roles.labeler === this.role) this.setupWebRTC();
    document.addEventListener('keyup', this.handleKeyUpEvents);
    document.addEventListener('keydown', this.handleKeyDownEvents);
    window.addEventListener('beforeunload', this.handleSaveCaptureFrame);
    window.addEventListener('unload', this.handleSaveCaptureFrame);
  },

  beforeUnmount() {
    this.$emit('updateLoading', true);
    document.removeEventListener('keyup', this.handleKeyUpEvents);
    document.removeEventListener('keydown', this.handleKeyDownEvents);
    window.removeEventListener('beforeunload', this.handleSaveCaptureFrame);
    window.removeEventListener('unload', this.handleSaveCaptureFrame);
    window.onbeforeunload = null;
    this.handleSaveCaptureFrame();
  },

  watch: {
    selectedLang(value) {
      this.setStepTranslations(value);
    },
  },

  methods: {
    ...mapActions(['setSelectedLanguage']),

    handleKeyDownEvents(event) {
      if (event.key?.toLowerCase() === 'f') this.saveCurrentFrame();
    },

    handleKeyDownEvents(event) {
      if (event.key?.toLowerCase() === 'f') this.saveCurrentFrame();
    },

    handleKeyUpEvents(event) {
      if (event.key === 'Escape') {
        this.$emit('closeModal');
      }
    },

    setStepTranslations(lang) {
      if (lang === this.translationLanguage) {
        this.stepsTranslationMapping = getTranslatedSteps(
          this.taskDetails,
          this.translationDetails
        );
      } else {
        this.stepsTranslationMapping = getTranslatedSteps(this.taskDetails);
      }
    },

    setTaskDetails(taskDetail, getData) {
      const temp = getStepsList(taskDetail);
      this.taskName = temp?.name || '';
      this.stepsList = temp?.steps || [];
      const { processes } = getTaskNameAndProcess(taskDetail);
      const stepToIndexMap = getStepToIndexMapping(processes);
      this.stepsToIndexMapping = stepToIndexMap;
      getData && this.getVideoMetaData();
    },

    getBarcodedSteps() {
      this.barcodedSteps = this.associateBarcodes
        ?.map((barcode) => barcode.step_index)
        .sort();
    },

    handleAddSaveFrame() {
      const { currentFrame } = this;
      if (!this.saveFrames.includes(currentFrame))
        this.saveFrames = [...this.saveFrames, currentFrame];
    },

    saveCurrentFrame() {
      if (this.loading || this.isVisualize) return;
      this.handleAddSaveFrame();
    },

    async handleSaveCaptureFrame() {
      if (!this.saveFrames.length) return;
      const payload = {
        task_id: this.taskId,
        frames: this.saveFrames,
        file_name: this.video.fileName,
        file_url: this.video.fileURL,
      };
      await httpClient.post(
        'generic/task/save_annotation_images/',
        payload,
        false,
        false,
        false,
        '',
        '',
        prodHostUrl
      );
      this.saveFrames = [];
    },

    removeFrame(frame) {
      this.saveFrames = this.saveFrames.filter((f) => f !== frame);
    },

    uploadCurrentFrameToS3() {
      const { fileName } = this.video;
      this.$refs.videoPlayer.uploadFrameForAnnotationtest1(
        this.taskName,
        fileName,
        this.currentFrame
      );
    },

    handleFrameChange(frame) {
      this.currentFrame = frame;
      const videoFPS = this.videoMetaData?.frameRate || this.video?.fps;
      const frameToMove = (Number(frame) / videoFPS).toFixed(25);
      this.$refs.video.currentTime = frameToMove;
    },

    getPreds(pred) {
      const key = JSON.stringify(pred);
      if (this.predsToStepsMap[key]) return this.predsToStepsMap[key];

      const result = [];

      pred = pred.reduce((res, el) => {
        res[el] = el;
        return res;
      }, {});

      for (let i = 0; i < this.taskStepsCount; i++) result.push(pred[i]);

      this.predsToStepsMap = {
        ...this.predsToStepsMap,
        key: result,
      };
      return result;
    },

    async parseTaskDetails() {
      const stepsDict = {};
      const entries = Object.entries(this.video?.predictions).map(
        ([frame, value]) => {
          const { pred } = value;
          const res = this.getPreds(pred);
          return [frame, { ...value, pred: res }];
        }
      );

      if (entries.length === 0) return;

      let prevValue = entries[0][1]?.pred || entries[0][1],
        prevKey = entries[0][0];

      entries.forEach(([key, value]) => {
        const first = value.pred ? value.pred : value;

        prevValue.forEach((prev, index) => {
          if (!stepsDict[first[index]]) {
            stepsDict[first[index]] = [{ start: key }];
            if (prev !== first[index]) {
              const lastIndex = stepsDict[prev].length - 1;
              const prevEl = stepsDict[prev][lastIndex];
              stepsDict[prev][lastIndex] = { ...prevEl, end: prevKey };
            }
          } else if (prev !== first[index]) {
            stepsDict[first[index]].push({ start: key });
            const lastIndex = stepsDict[prev].length - 1;
            const prevEl = stepsDict[prev][lastIndex];
            stepsDict[prev][lastIndex] = { ...prevEl, end: prevKey };
          }
        });

        prevValue = value?.pred || value;
        prevKey = key;
      });
      const prev = prevValue[0];

      const lastIndex = stepsDict[prev].length - 1;
      const prevEl = stepsDict[prev][lastIndex];
      stepsDict[prev][lastIndex] = { ...prevEl, end: prevKey };

      this.stepsToVisualize = stepsDict;
    },

    async getVideoMetaData() {
      if (!this.video) return;
      const payload = { id: this.video?.id };
      const [error, res] = await TaskRecordService.getVideoMetaData(
        payload,
        false
      );
      if (error) {
        console.log({ error });
        return resolve(30);
      }
      const { data } = res;
      if (!data) {
        this.setDefaultVideoMetaData();
        return;
      }

      this.setVideoMetaData(data);
      if (this.isVisualize) this.parseTaskDetails();
      this.$emit('stopLoading');
    },

    setVideoMetaData(data) {
      let { duration, fps, total_frames } = data;

      this.totalFrames = total_frames;

      this.videoMetaData = {
        fps,
        total_frames,
        duration,
      };
    },

    setDefaultVideoMetaData() {
      if (!this.video) return;
      const { fps, duration } = this.video;
      this.totalFrames = getNumberOfFrames(duration, fps);
    },
  },
};
</script>
<style scoped>
video {
  object-fit: cover;
}

.annotate-top-col {
  /* height: 300px; */
}
.annotate-video-col {
  /* height: 300px; */
  /*  */
}
@media only screen and (max-width: 768px) {
  .annotate-top-col {
    height: 470px;
  }
  .annotate-video-col {
    height: 350px;
  }
}
@media only screen and (max-width: 400px) {
  .annotate-top-col {
    height: 520px;
  }
}
</style>
